.App {
  margin: 0;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.body {
  background: burlywood;
}

.App-link {
  color: #61dafb;
}

.page-container {
  width: 100%;
  height: 100%;
}

.nav {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.nav-link {
  text-decoration: 'none';
  width: 100%;
  color: black !important;
}

.nav-icon {
  margin-right: 5px;
}

.nav-bar {
  margin-top: 10px;
  margin-right: 40px;
  margin-left: auto;
  float: right;
}

.page-title {
  margin-top: 10px;
  margin-left: 40px;
  float: left;
}

.page-title:hover {
  cursor: pointer;
}

.content-container-home {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}

.vertical-button {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 40px;
  padding-bottom: 40px;
  width: 50%;
  height: 80%;
  border: slategray solid;
  border-radius: 0.5rem;
  font-size: 16pt;
  font-family: Arial, Helvetica, sans-serif;
  background-color: wheat;
}

.type-select-button {
  width:inherit;
  margin-top: 20px;
  background-color: antiquewhite;
}

.type-select-button-logout {
  align-self: "end";
  margin-top: "20px";
  margin-right: "40px";
}

.type-select-button-logout:hover {
  cursor: pointer;
  color: #6c757d;
}

.content-container {
  margin-top: 10px;
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: gainsboro solid 5px;
  border-bottom: gainsboro solid 3px;
  background-color: whitesmoke;
}

.content-card {
  width: 50%;
  height: 60vh;
  margin-top: 5px;
  border: slategray solid;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-left: auto;
  margin-right: 20px;
  background-color: whitesmoke;

}

.control-list {
  width: 20%;
  margin-top: 5px;
  margin-left: auto;
  margin-right: 40px;
  border: slategray solid;
  border-radius: 0.5rem;
  float: right;
  height: 60vh;
  max-height:60vh;
  overflow: scroll;
  background-color: white;

}

.word-list-card {
  padding: 10px;
}

.word-flip-card {
  display: block;
  text-align: center;
  border: slategray solid;
  padding: 15px;
  border-radius: 0.5rem;
  height: 40vh;
  width: 45vw;
}

.word-flip-card-h4 {
  margin-bottom:40px;
  margin-top:40px;
}

.content-word-button {
  margin-top: 20px;
  width: 40vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.content-dialogue-button{
  margin-top: 20px;
  width: 40vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.side-nav-menu-list {
  border: 0 none;
  padding-left: 0;
  list-style: none;
}

.side-nav-menu {
  width: 20%;
  height: 79vh;
  margin-right: 20px;
  border-right: gainsboro solid 2px;
  display: flex;
  flex-direction: column;
}

.side-nav-menu-list-item {
  list-style-type: none;
  display: flex;
  align-items: center;
  height: 10vh;
  width: auto;
  padding: 5vh;
  font-size: 16pt;
}

.side-nav-menu-list-item:hover {
  background-color: gainsboro;
}

.side-logout-button {
  margin-top: auto;
  align-self: flex-end;
}

.dialogue-list-item {
  height: 8vh;
}

.dialogue-content-container {
  width: 45vw;
  height: 40vh;
}

.username {
  color: gray;
  font-size: 14px;
}

.dialogue-add-button {
  justify-self: start;
  margin-right: 20px;
}

.dialogue-add-button:hover {
  cursor: pointer;
}

.button-hover:hover {
  cursor: pointer;
}

.story-list-card {
  padding: 10px;
}

.story-list-card:hover {
  cursor: pointer;
}

.story-flip-card {
  display: block;
  text-align: center;
  border: slategray solid;
  padding: 15px;
  border-radius: 0.5rem;
  height: 40vh;
  width: 45vw;
}

.story-flip-card-h4 {
  margin-bottom:40px;
  margin-top:40px;
}

.story-flip-card-exp {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  border: slategray solid;
  padding: 15px;
  border-radius: 0.5rem;
  height: 40vh;
  width: 45vw;
}

.login_form {
  display: flex;
  flex-direction: column;
  max-width: 40vw;
  margin: 40px auto;
  padding-left: 40px;
  padding-right: 40px;
  border-radius: 4px;
}

.login_input {
  display: block;
  box-sizing: border-box;
  width: 100%;
  border-radius: 4px;
  border: 1px solid black;
  padding: 10px 15px;
  margin-bottom: 10px;
  font-size: 14px;
}

.login_label {
  line-height: 2;
  text-align: left;
  display: block;
  margin-bottom: 13px;
  margin-top: 20px;
  color: black;
  font-size: 16px;
  font-weight: 200;
}

.login_submit {
  background-color: #394fde;
  color: white;
  text-transform: uppercase;
  border: none;
  margin-top: 40px;
  margin-bottom: 20px;
  padding: 20px;
  font-size: 16px;
  font-weight: 60;
  letter-spacing: 5px;
}

.login_submit:hover {
  background: #164ebf;
}

.login_submit:active {
  transition: 0.3s all;
  transform: translateY(3px);
  border: 1px solid transparent;
  opacity: 0.8;
}

.login_submit:disabled {
  opacity: 0.4;
}

.login_submit:hover {
  transition: 0.3s all;
}

.login_submit {
  -webkit-appearance: none;
}

.login_buttons {
  display: flex;
  flex-direction: row;
}

.invalid_p {
  color: #bf1650;
}

.invalid_p::before {
  display: inline;
  content: "⚠ ";
}

.setting-select {
  width: 20vw;
  height: 40px;
  border-radius: 8px;
}

.story-pop-radio {
  margin-right: 10px;
  margin-left: 10px;
}